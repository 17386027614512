export const findNorthWestAndSouthEast = (geofence) => {
  let minLat = geofence[0].lat;
  let maxLat = geofence[0].lat;
  let minLng = geofence[0].lng;
  let maxLng = geofence[0].lng;
  
  // Iterate through the array to find min and max values
  for (const coordinate of geofence) {
    minLat = Math.min(minLat, coordinate.lat);
    maxLat = Math.max(maxLat, coordinate.lat);
    minLng = Math.min(minLng, coordinate.lng);
    maxLng = Math.max(maxLng, coordinate.lng);
  }
  
  // Display the northwest and southeast corners
  return {"southwest": [minLng, minLat], "northeast": [maxLng,maxLat]}
  };