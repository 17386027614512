import { useState, useEffect, useRef } from "react";
import { FiNavigation2 } from "react-icons/fi";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

function MapboxRobot(props) {
  const mapContainerRef = useRef(null); // Ref for the map container
  const mapRef = useRef(null); // Ref for the map instance
  const [robotMarker, setRobotMarker] = useState(null);
  const [dropMarker, setDropMarker] = useState(null);
  const [pickupMarker, setPickupMarker] = useState(null);
  const [dropLocationPopup, setDropLocationPopup] = useState(null);
  const [userControllingMap, setUserControllingMap] = useState(false);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mrlakshyagupta/clmribzic029m01qx4biqdw6s",
        zoom: process.env.REACT_APP_MAP_ZOOM,
        maxBounds: [
          props?.maxBoundCordinates?.southwest,
          props?.maxBoundCordinates?.northeast
        ],
      });

      mapRef.current = newMap;

      const robotMarkerDiv = document.createElement("div");
      robotMarkerDiv.style.width = "35px";
      robotMarkerDiv.style.height = "35px";
      robotMarkerDiv.style.borderRadius = "100%";
      robotMarkerDiv.style.backgroundImage = "url(/assets/images/robot-icon-2.svg)";

      const dropMarkerDiv = document.createElement("div");
      dropMarkerDiv.style.width = "35px";
      dropMarkerDiv.style.height = "35px";
      dropMarkerDiv.style.borderRadius = "100%";
      dropMarkerDiv.style.backgroundImage = "url(/assets/images/dropoff.svg)";

      const pickupMarkerDiv = document.createElement("div");
      pickupMarkerDiv.style.width = "35px";
      pickupMarkerDiv.style.height = "35px";
      pickupMarkerDiv.style.borderRadius = "100%";
      pickupMarkerDiv.style.backgroundImage = "url(/assets/images/PickUpLocation-icon.svg)";

      let newRobotMarker, newDropMarker, newPickupMarker;

      newRobotMarker = new mapboxgl.Marker(robotMarkerDiv)
        .setLngLat([
          props?.robotLocation?.longitudeSlam || 0,
          props?.robotLocation?.latitudeSlam || 0,
        ])
        .addTo(newMap);

      newDropMarker = new mapboxgl.Marker(dropMarkerDiv)
        .setLngLat([
          parseFloat(props?.orderDropLocation?.longitude) || 0,
          parseFloat(props?.orderDropLocation?.latitude) || 0,
        ])
        .addTo(newMap);

      newPickupMarker = new mapboxgl.Marker(pickupMarkerDiv)
        .setLngLat([
          parseFloat(props?.orderPickupLocation?.longitude) || 0,
          parseFloat(props?.orderPickupLocation?.latitude) || 0,
        ])
        .addTo(newMap);

      const customPopup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      customPopup
        .setLngLat([
          props?.orderDropLocation?.longitude || 0,
          props?.orderDropLocation?.latitude || 0,
        ])
        .addTo(newMap)
        .setHTML(
          `<div class="custom-popup"><p>${props?.orderDropLocation?.locationName}</p></div>`
        );

      setRobotMarker(newRobotMarker);
      setPickupMarker(newPickupMarker);
      setDropMarker(newDropMarker);
      setDropLocationPopup(customPopup);
    };

    if (!mapRef.current) {
      initializeMap();
    }
  }, [mapRef, props]);

  useEffect(() => {
    if (robotMarker) {
      if (props?.robotLocation?.headingSlam || props?.robotLocation?.heading) {
        let rotation = parseFloat(props?.robotLocation?.headingSlam || 0);
        robotMarker.setRotation(rotation).setRotationAlignment("map");
      }
    }

    if (dropMarker) {
      dropMarker
        .setLngLat([
          parseFloat(props?.orderDropLocation?.longitude) || 0,
          parseFloat(props?.orderDropLocation?.latitude) || 0,
        ])
        .setRotationAlignment("map");

      dropLocationPopup
        .setLngLat([
          props?.orderDropLocation?.longitude || 0,
          props?.orderDropLocation?.latitude || 0,
        ])
        .setHTML(
          `<div class="custom-popup"><p>${props?.orderDropLocation?.locationName}</p></div>`
        );
    }

    if (pickupMarker) {
      pickupMarker
        .setLngLat([
          parseFloat(props?.orderPickupLocation?.longitude) || 0,
          parseFloat(props?.orderPickupLocation?.latitude) || 0,
        ])
        .setRotationAlignment("map");
    }

    if (
      !userControllingMap &&
      !!Number(process.env.REACT_APP_USER_MAP_CONTROL) &&
      props?.robotLocation?.longitudeSlam &&
      props?.orderDropLocation?.longitude
    ) {
      const bounds = new mapboxgl.LngLatBounds([
        props?.robotLocation?.longitudeSlam,
        props?.robotLocation?.latitudeSlam,
      ], [
        props?.orderDropLocation?.longitude,
        props?.orderDropLocation?.latitude,
      ]);

     mapRef.current.fitBounds(bounds, {
        padding: 100,
        duration: 200,
      });
    }
  }, [props, robotMarker, dropMarker, pickupMarker, dropLocationPopup, userControllingMap]);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {userControllingMap &&
        !!Number(process.env.REACT_APP_USER_MAP_CONTROL) ? (
          <div
            className={`cursor-pointer flex items-center gap-2 sidebar bg-[#00B7D4] text-white p-[6px_12px] z-[1] absolute bottom-0 left-0 m-1 rounded-[4px] h-[30px]`}
            onClick={() => setUserControllingMap(false)}
          >
            <FiNavigation2 strokeWidth={3} />
            <span className="text-xs font-bold uppercase">Re-centre</span>
          </div>
        ) : null}
      <div
        ref={mapContainerRef}
        id="map-container-id"
        className="map-container"
        style={{
          height: "100%",
          position: "absolute",
          width: "100%",
        }}
      ></div>
    </div>
  );
}

export default MapboxRobot;
