import React, { useState } from "react";
import Help from "../modals/Help";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
function Footer(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [fleetConfig, setFleetConfig] = useState(
    JSON.parse(localStorage.getItem("fleetConfig") || "{}")
  );
  const [supportNumber, setSupportNumber] = useState(
    fleetConfig?.operatorPhone || process.env.REACT_APP_HELP_NUMBER
  );
  const [supportMail, setSupportMail] = useState(
    fleetConfig?.supportMail || process.env.REACT_APP_SUPPORT_MAIL
  );

  const handleClose = (reason) => {
    if (reason && reason === "backdropClick") return;
    props.setOpen(false);
  };
  const handleCallClick = () => {
    window.location.href = `tel:${supportNumber}`;
  };
  const loaderStyle = {
    border: "4px solid rgba(0, 0, 0, 0.1)",
    borderTop: "4px solid #000",
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    animation: "spin 1s linear infinite",
  };

  const keyframesAnimation = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <>
      <div className="bottom-0 fixed w-full flex flex-col">
        <div className="flex bg-[#F1F0F5] p-4 text-black justify-evenly">
          <div onClick={handleCallClick} className="">
            <CallOutlinedIcon />
            <p className="font-bold text-xs">{supportNumber} </p>
          </div>
          <div className="">
            <a
              className="support__mail text-black"
              href={`mailto:${supportMail}`}
            >
              <EmailOutlinedIcon />
              <p className="font-bold text-xs">{supportMail}</p>
            </a>
          </div>
        </div>
        <div className="flex bg-white text-center p-2 text-black gap-x-2 justify-center">
          <p className="font-normal text-xs self-center">Powered by</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="114.49pt"
            height="17.67pt"
            viewBox="0 0 307.86 47.526"
          >
            <g id="ottonomy-svg" transform="translate(-25.2 -6.319)">
              <g
                id="Group_6"
                data-name="Group 6"
                transform="translate(103.642 20.821)"
              >
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M107.384,33.842a6.3,6.3,0,0,1-1.516,4.674,6.3,6.3,0,0,1-4.674,1.516H93.489a6.3,6.3,0,0,1-4.674-1.516A6.3,6.3,0,0,1,87.3,33.842V23.989a6.3,6.3,0,0,1,1.516-4.674A6.3,6.3,0,0,1,93.489,17.8h7.705a6.3,6.3,0,0,1,4.674,1.516,6.3,6.3,0,0,1,1.516,4.674Zm-5.558-9.853a1.675,1.675,0,0,0-1.895-1.895H94.753a1.675,1.675,0,0,0-1.895,1.895v9.853a1.675,1.675,0,0,0,1.895,1.895h5.179a1.675,1.675,0,0,0,1.895-1.895Z"
                  transform="translate(-87.3 -17.8)"
                  fill="#020202"
                />
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M116.4,39.574h-5.558V22.395H104.4V18.1h18.442v4.295H116.4Z"
                  transform="translate(-82.8 -17.721)"
                  fill="#020202"
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M131.4,39.574h-5.558V22.395H119.4V18.1h18.442v4.295H131.4Z"
                  transform="translate(-78.853 -17.721)"
                  fill="#020202"
                />
                <path
                  id="Path_5"
                  data-name="Path 5"
                  d="M155.184,33.842a6.3,6.3,0,0,1-1.516,4.674,6.3,6.3,0,0,1-4.674,1.516H141.29a6.3,6.3,0,0,1-4.674-1.516,6.3,6.3,0,0,1-1.516-4.674V23.989a6.3,6.3,0,0,1,1.516-4.674A6.3,6.3,0,0,1,141.29,17.8h7.705a6.3,6.3,0,0,1,4.674,1.516,6.3,6.3,0,0,1,1.516,4.674Zm-5.558-9.853a1.675,1.675,0,0,0-1.895-1.895h-5.179a1.675,1.675,0,0,0-1.895,1.895v9.853a1.675,1.675,0,0,0,1.895,1.895h5.179a1.675,1.675,0,0,0,1.895-1.895Z"
                  transform="translate(-74.721 -17.8)"
                  fill="#020202"
                />
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M172.095,39.574h-5.811l-7.326-12.884V39.574H153.4V18.1h5.811l7.326,12.884V18.1h5.558Z"
                  transform="translate(-69.905 -17.721)"
                  fill="#020202"
                />
                <path
                  id="Path_7"
                  data-name="Path 7"
                  d="M190.584,33.842a6.3,6.3,0,0,1-1.516,4.674,6.3,6.3,0,0,1-4.674,1.516H176.69a6.3,6.3,0,0,1-4.674-1.516,6.3,6.3,0,0,1-1.516-4.674V23.989a6.3,6.3,0,0,1,1.516-4.674A6.3,6.3,0,0,1,176.69,17.8h7.705a6.3,6.3,0,0,1,4.674,1.516,6.3,6.3,0,0,1,1.516,4.674Zm-5.558-9.853a1.675,1.675,0,0,0-1.895-1.895h-5.179a1.675,1.675,0,0,0-1.895,1.895v9.853a1.675,1.675,0,0,0,1.895,1.895h5.179a1.675,1.675,0,0,0,1.895-1.895Z"
                  transform="translate(-65.405 -17.8)"
                  fill="#020202"
                />
                <path
                  id="Path_8"
                  data-name="Path 8"
                  d="M207.242,27.574l-4.295,7.705h-4.295l-4.295-7.705v12H188.8V18.1h5.811L200.8,29.468,206.99,18.1H212.8V39.574h-5.558v-12Z"
                  transform="translate(-60.589 -17.721)"
                  fill="#020202"
                />
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M208.7,18.1h5.81l5.053,10.358L224.616,18.1h5.811l-8.084,15.79v5.684h-5.558V33.89Z"
                  transform="translate(-55.352 -17.721)"
                  fill="#020202"
                />
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M224.2,31.4h5.558v4.547H224.2Z"
                  transform="translate(-51.273 -14.221)"
                  fill="#020202"
                />
                <path
                  id="Path_11"
                  data-name="Path 11"
                  d="M241.089,39.574H230.1V35.279h2.779V22.395H230.1V18.1h10.989v4.295h-2.779V35.279h2.779Z"
                  transform="translate(-49.721 -17.721)"
                  fill="#020202"
                />
                <path
                  id="Path_12"
                  data-name="Path 12"
                  d="M260.484,33.842a6.3,6.3,0,0,1-1.516,4.674,6.3,6.3,0,0,1-4.674,1.516h-7.705a6.3,6.3,0,0,1-4.674-1.516,6.3,6.3,0,0,1-1.516-4.674V23.989a6.3,6.3,0,0,1,1.516-4.674,6.3,6.3,0,0,1,4.674-1.516h7.705a6.3,6.3,0,0,1,4.674,1.516,6.3,6.3,0,0,1,1.516,4.674Zm-5.558-9.853a1.675,1.675,0,0,0-1.895-1.895h-5.179a1.675,1.675,0,0,0-1.895,1.895v9.853a1.675,1.675,0,0,0,1.895,1.895h5.179a1.675,1.675,0,0,0,1.895-1.895Z"
                  transform="translate(-47.01 -17.8)"
                  fill="#020202"
                />
              </g>
              <g
                id="Group_7"
                data-name="Group 7"
                transform="translate(25.2 6.319)"
              >
                <path
                  id="Path_13"
                  data-name="Path 13"
                  d="M82.321,43.558,73.1,53.663c-.253.379-.884.126-.884-.379V26.505a3.344,3.344,0,0,0-3.284-3.284H53.9a.558.558,0,0,1-.379-.884l9.6-9.474a1.434,1.434,0,0,1,1.011-.379H72.09a.272.272,0,0,0,.253-.253V6.8a.558.558,0,0,1,.884-.379l9.221,10.105a1.434,1.434,0,0,1,.379,1.011V42.548A1.559,1.559,0,0,1,82.321,43.558Z"
                  transform="translate(-17.774 -6.319)"
                  fill="#020202"
                />
                <path
                  id="Path_14"
                  data-name="Path 14"
                  d="M62.463,11.958l-9.6,9.347a1.622,1.622,0,0,1-1.263.505H39.347a3.344,3.344,0,0,0-3.284,3.284V38.105a3.344,3.344,0,0,0,3.284,3.284H71.179a3.344,3.344,0,0,1,3.284,3.284v7.074a.543.543,0,0,1-.505.505H35.053A9.865,9.865,0,0,1,25.2,42.4V18.021a1.93,1.93,0,0,1,.505-1.263l4.8-4.8a2.028,2.028,0,0,1,1.389-.632l30.19-.126C62.59,11.2,62.842,11.705,62.463,11.958Z"
                  transform="translate(-25.2 -5.035)"
                  fill="#020202"
                />
              </g>
              <text
                id="TM"
                transform="translate(317.06 21.869)"
                fontSize="10"
                fontFamily="RussoOne-Regular, Russo One"
              >
                <tspan x="0" y="0">
                  TM
                </tspan>
              </text>
            </g>
          </svg>
        </div>
      </div>
    </>
  );
}

export default Footer;
