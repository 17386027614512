import axios from "axios";

export const getdeliverytime = (orderId) => {
  return axios
    .get(
      `${process.env.REACT_APP_BACKEND_URL}/getOrdersETA/${orderId}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getOrderDetails = (orderID) => {
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}/order/details`,
      {
        orderId: orderID,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BACKEND_TOKEN}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
// export const getmaplist = (token) => {
//   return axios
//     .get(`${process.env.REACT_APP_BACKEND_URL}/map/v1/getMapList`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     })
//     .then((res) => {
//       return res;
//     });
// };
export const getGeofenceByFleetId = (fleetId) => {
  return axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/robot/v2/getFleetGeofence`, 
    {
      fleetId:fleetId
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_BACKEND_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};
export const getFleetConfig = async (orderId) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/fleet/config?orderId=${orderId}`)
    return result
  }
  catch(err){
    throw err

  }
}
